import { Box } from '@mui/material';
import { PATHS } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconArrow } from 'assets/icons/chevron-down.svg';
import { useRef, useState } from 'react';
import { useOutsideClick } from 'common/helpers';
import classes from './AdvantageMenu.module.scss';


const AdvantageMenu = () => {
  const { t } = useTranslation();
  const [ open, setOpen ] = useState(false);
  const navigate = useNavigate();
  const dropRef = useRef(null);

  const handleNavigate = (path) => {
    if (open) {
      navigate(path);
      setOpen(false);
    } else {
      setOpen(prev => !prev);
    }
  };

  useOutsideClick(dropRef, () => setOpen(false), open);

  return (
    <Box
      className={classes.links}
      ref={dropRef}
    >
      <Box
        className={classes.link}
        onClick={() => handleNavigate(PATHS.advantages)}
      >
        {t('Advantages')}
        <IconArrow className={open ? classes.arrowReverse : ''}/>
      </Box>
      {
        open &&
        <Box
          className={`${classes.secondLink} ${classes.link}`}
          onClick={() => handleNavigate(PATHS.afterCourses)}
        >
          {t('AfterCourses')}
        </Box>
      }
    </Box>
  );
};

export default AdvantageMenu;
