import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'direction';

const ENDPOINTS = { direction: '/api/v1/career_center/directions/' };

export const getDirection = createAsyncThunk(
  `${name}/getDirection`,
  async (_, { extra: api }) => {
    const token = JSON.parse(localStorage.getItem('token'));

    const token_type = token?.token_type;
    const access_token = token?.access_token;

    const response = await api.get(`${ENDPOINTS.direction}`,
      { headers: { Authorization: `${token_type} ${access_token}` } }
    );
    return response.data;
  }
);

const initialState = {
  [ name + 'Status' ]: REQUEST_STATUSES.NOT_REQUESTED,
  [ name + 'Data' ]: {},
  [ name + 'Error' ]: null
};

const directionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getDirection, {
      status: [ name + 'Status' ],
      data: [ name + 'Data' ],
      error: [ name + 'Error' ],
      options: { concat: false }
    });
  }
});

export default directionSlice;

export const directionSelect = state => state?.[ name ];
