import { NavLink } from 'react-router-dom';
import { generateRel } from 'common/helpers';
import { externalRecourse } from 'common/constants';
import { LanguageMenu } from '../LanguageMenu';
import AdvantageMenu from '../AdvatageMenu/AdvantageMenu';
import classes from './navigation.module.scss';


const Navigation = ({ headerPaths }) => {
  return (
    <nav className={classes.nav}>
      {headerPaths.map((item, i) =>
        <NavLink
          className={({ isActive }) => isActive ? classes.activeLink : classes.link}
          to={item.url}
          key={i}
          target={item.target ? '_blank' : ''}
          {...generateRel(item.target)}
        >
          {item.name}
        </NavLink>)}
      <AdvantageMenu/>
      <NavLink
        className={classes.link}
        to={externalRecourse.geekstudio}
        target='_blank'
      >
        GeekStudio
      </NavLink>
      <LanguageMenu />
    </nav>
  );
};

export default Navigation;
