import { useMediaQuery } from '@mui/material';
import i18n from 'i18next';
import { useEffect, useRef } from 'react';
import { REQUEST_STATUSES } from './constants.js';

/**
 * Creates 3 three addCases for query handling
 * @param {Object} builder
 * @param {Function} thunk
 * @param {Object} fields { status: string, data: string, error: string }
 * @param {Object} options { concat: true }
 */

export const addQueryCases = (
  builder,
  thunk,
  { status, data, error, options = { concat: true } },
) => {
  builder
    .addCase(thunk.pending, state => {
      state[ status ] = REQUEST_STATUSES.REQUESTED;
    })
    .addCase(thunk.fulfilled, (state, { payload }) => {
      state[ status ] = REQUEST_STATUSES.SUCCEEDED;
      if (options.concat) {
        state[ data ] = payload.results;
      } else {
        state[ data ] = payload;
      }
    })
    .addCase(thunk.rejected, (state, action) => {
      state[ status ] = REQUEST_STATUSES.FAILED;
      state[ error ] = action.error;
    });
};

/** FUNCTION TO CHANGE LANGUAGE
 * @param {string} lang { en || ru || ky }
 */

export const changeLang = (lang) => {
  i18n.changeLanguage(lang);
  const currLang = localStorage.getItem('lang');
  if (currLang !== lang) {
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
};

export const currentLang = () => {
  const currLang = localStorage.getItem('lang');
  i18n.changeLanguage(currLang);
};

export const declOfMonth = (number, titles = [
  i18n.t('Month'),
  i18n.t('Months1'),
  i18n.t('Months2')
]) => {
  const cases = [
    2,
    0,
    1,
    1,
    1,
    2
  ];
  return titles[ (number % 100 > 4 && number % 100 < 20) ? 2 : cases[ (number % 10 < 5) ? number % 10 : 5 ] ];
};

export const declOfYearsAge = (number, titles = [
  i18n.t('Year'), // лет
  i18n.t('YearTwo'), // год
  i18n.t('YearThree'), // года
]) => {
  if (number % 10 >= 2 && number % 10 <= 4) {
    return titles[ 2 ];
  }
  else if (number % 10 === 1 && number !== 11) {
    return titles[ 1 ];
  }
  else {
    return titles[ 0 ];
  }

};

export const useChangeFromMedia = (swiperOpts, mobileOpts, mediumDesktop, miniMobileOpts, tabletOpts) => {
  const mediaMobile = useMediaQuery('(max-width:431px)');
  const tablet = useMediaQuery('(max-width:1080px)');
  const mediaMediumDesktop = useMediaQuery('(max-width: 1485px)');
  const miniMobile = useMediaQuery('(max-width: 340px)');
  if (miniMobile) return miniMobileOpts;
  if (mediaMobile) return mobileOpts;
  if (tablet) return tabletOpts;
  if (mediaMediumDesktop) return mediumDesktop;
  return swiperOpts;
};

// func for genereting rel in a
export const generateRel = (opt) => (opt ? { rel: 'nofollow' } : {});

// style for toast from react-toastify
export const toastStyle = {
  autoClose: 5000,
  pauseOnHover: false,
  icon: false,
  theme: 'colored',
  style: {
    background: '#343332',
    borderRadius: 0,
  },
};

// change value like phone number
export const maskPhoneFormat = (phoneNumber) => {
  const x = phoneNumber
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})/);
  return `+996 (${x[ 2 ]}${x[ 3 ] && ') '}${x[ 3 ]}${x[ 4 ] && '-'}${x[ 4 ]}${x[ 5 ] && '-'}${x[ 5 ]}`;
};


export const useLatest = (value) => {
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [ value ]);

  return valueRef;
};


export const useOutsideClick = (elemRef, handler, attached) => {
  const latestHandler = useLatest(handler);

  useEffect(() => {
    if (!attached) return;
    const handleClick = (e) => {
      if (!elemRef.current || !(elemRef.current instanceof HTMLElement)) return;

      if (!elemRef.current.contains(e.target)) {
        latestHandler.current();
      }
    };

    const timeout = setTimeout(() => document.addEventListener('click', handleClick));

    return () => {
      document.removeEventListener('click', handleClick);
      clearTimeout(timeout);
    };

  }, [
    attached,
    latestHandler,
    elemRef,
  ]);
};
